*{

  font-family: 'Montserrat', sans-serif;

}
.footer {
  margin-left: 7%; 
  margin-right: 0% ;
  color: #231060;
  height: auto;
}
.footer .links ul {
  list-style-type: none;
}
.footer .links li a {
  color: #231060;
  font-weight: 100;
  font-size: 12px;
}
.footer .links li a:hover {
  text-decoration: none;
  color: #4180CB;
}
.footer .about-company i {
  font-size: 25px;
}
.footer .about-company a {
  color: #231060;
  transition: color 0.2s;
}
.footer .about-company a:hover {
  color: #4180CB;
}
.footer .location i {
  font-size: 18px;
}
.footer .copyright p {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
} 

.footer-left-text{

  width: 223px;
  height: auto;
  font-size: 12px;  

}


.footer-list{


  margin: 20px;
  margin-top: 48px;
}
.footer-list li a {

  text-decoration: none;

}

.footer-list-div{
}

.social-icon{


  float: right; 
  margin-left: 10px;
} 

.social-section{


  float: right !important; 
  margin-left: -9% !important;
   margin-top: -1px !important;
}


@media only screen and (max-width: 820px) {

  
  .footer {
    margin-left: 5.5% !important; 
    margin-right: 0%  !important;
  }

  .social-section{


      float: right; margin-left: -3% !important; margin-top: -1px;
    }
}

@media only screen and (max-width: 600px) {

  .footer {
      margin-left: 0%; 
      margin-right: 0% ;
      clear: both !important;
      margin: auto !important;
    
    }
    
  .footer-list-div{
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;



    }
    
    .footer-left-text{
    
      width: 100% !important;
    }
    
    
    .footer-list{
    clear: both !important;
    margin: auto !important;
    }

    .social-section{


      float: none !important;
       margin-left: -1% !important;
       margin-top: 10% !important;
       justify-content: center !important;
      align-items: center !important;
      text-align: center !important;

    }
    .social-icon{

      float: none !important;
      } 
}

