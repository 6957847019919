*{

    font-family: 'Montserrat', sans-serif;
  
  }
.first-left-card{


    background: transparent linear-gradient(159deg, #FCFCFD 0%, #E5EBF5 100%) 0% 0% no-repeat padding-box; 


} 

.card4{

    width: auto;
  
  }
  
  .card5{
  
  width: auto;
  
  }
  
  .card-horizontal1 {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .card-horizontal2 {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .right-to-left-card-img{
    width: 45.75rem;
    height: 30.625rem;
    margin-left: 8%;
  
  }
  
  .left-to-right-card-img{
    width: 45.75rem;
    height: 30.625rem;
  
  }
  
  .card4-body{
    width: 22.438rem !important;

  
  } 
  
  .card4-title{
  
    font-weight: 700;
    font-size: 20px;
  }
  
  
  .card4-text{
  
    font-size: 16px;
  }
  
  .first-cont{
  
  margin-left: -5%;
  }
  
  .card5-body{
    width: 22.438rem ;
    margin-left: 5% !important;
    
  
  } 
  
  .card5-title{
  
    font-weight: 700;
    font-size: 20px;
  }
  
  
  .card5-text{
  
    font-size: 16px;
  }
  


  @media only screen and (max-width:600px) {


    .card-horizontal1 {
        display: flex;
        flex-direction: column !important;
        clear: both !important;
        margin: auto;
        justify-content: center !important;
        align-items: center;
      }
      .card-horizontal2 {
        display: flex;
        flex-direction: column-reverse !important;
        clear: both !important;
        margin: auto;
        justify-content: center !important;
        align-items: center;
      }
    
     
      .right-to-left-card-img{
        max-width: 23rem !important;
        height: 18.438rem !important;
        margin-left: -5% !important;    
      }
    
      .left-to-right-card-img{
        max-width: 21rem !important;
        height: 18.438rem !important;
        margin-left:5%  !important;    
    
      }
    
    
    .card4-body{
      width: 100%  !important;
      clear: both !important;
      
    
    }
    
    .first-cont{
    
      margin-left: 0% !important;
    
    }
    
    .card4-body{
      width: 100% !important;
      clear: both !important;
      margin: auto !important;
    
    } 
    
    
    .card5-body{
      width: 100%  !important;
      clear: both !important;
      margin: auto !important;
    
    
    }


    .left-section{

        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        margin-top: 1rem !important;
  
      }


      .img-square-wrapper{

        width: auto !important;
      }
    
  }


  @media only screen and (max-width:820px) {

    .right-to-left-card-img{
        width:25rem !important;
        height: 295px !important;
        margin-left: 0px;
      }
    
      .left-to-right-card-img{
        width: 25.625rem !important;
        height: 295px !important;
    
      }
      .card4-body{
        width: 100%  !important;
        clear: both !important;
        
      
      }
      
      .first-cont{
      
        margin-left: 0% !important;
      
      }
      
      .card4-body{
        width: 100% !important;
        clear: both !important;
        margin: auto !important;
      
      }

      .card5-body{
        width: 100%  !important;
        clear: both !important;
        margin: auto !important;
      
      
      }
    
  }
