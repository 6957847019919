.Request-form {
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0px 0px 4px 0px #00000040;
  background: #FFFFFF;

}

.grid-view-inputs {

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.input-box {

  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.input-box .form-control {

  width: 460px;
  border: 1px solid #CED4DA;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

.fill-data-h2 {

  color: #808080;
}

.submit-btn {

  width: 100%;
  height: 48px;
  border: 0px;
  border-radius: 10px;
  background-color: #0175FF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  color: white;
  font-weight: 700;
}

.submit-btn[type="submit"]:hover {
  background: linear-gradient(174.83deg, #00EEFF -407.2%, #0077FF 755.2%);
  color: #FFF;
}

.modules-grid-view {

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 1rem;
  column-gap: 1rem;
  row-gap: 1rem;
}

.single-module-flex-view {
  padding: 15px;
  box-shadow: 0px 0px 4px 0px #00000054 !important;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  column-gap: 1rem;
  border-radius: 5px;
  background-color: #F9FAFB;
  transition: background-color 0.3s, color 0.3s;
}

.single-module-flex-view:hover {
  background-color: #f0f0f0;
}

.single-module-flex-view.selected {
  background: linear-gradient(270deg, #00EEFF -20.9%, #0077FF 153.32%);
  color: #fff;
  border-color: #00EEFF;
  font-weight: 700;
}

.input-box .react-tel-input .form-control {

  width: 460px;
  border: 1px solid #CED4DA;

}



@media only screen and (max-width: 480px) {


  .input-box .react-tel-input .form-control {

    width: 100% !important;
    border: var(--bs-border-width) solid var(--bs-border-color);

  }

  .input-box .form-control {

    width: 100%;

  }

  .Request-form {
    display: flex;
    width: 90%;
    border: 1px solid #D9D9D9;
    justify-content: center;
    border-radius: 20px;
    padding: 2rem;

  }



  .grid-view-inputs {

    display: flex;
    flex-direction: column;
    
    column-gap: 1rem;
  }


  .modules-grid-view {

    display: grid;
    grid-template-columns: 1fr ;
    margin-top: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }


}


.odoo-icon {

  width: 40px;
  height: 40px;

}

.odoo-icon-container {

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 5px;
}

.spaning-module {

  margin-top: 1rem;

}

@media (min-width: 600px) and (max-width: 1024px) {



  .modules-grid-view {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    margin-top: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .input-box .form-control {

    width: 100%;

  }
  .input-box .react-tel-input .form-control {

    width: 100% !important;
    border: var(--bs-border-width) solid var(--bs-border-color);

  }

  .Request-form {
    display: flex;
    width: 90%;
    justify-content: center;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0px 0px 4px 0px #00000040;
    background: #FFFFFF;
  
  }


}


.progress-container {
  position: relative;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 20px;
  width: 100%;
  margin: 15px 0;
}

.progress-bar {
  background-color: #4caf50;
  height: 100%;
  width: 0%;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.progress-container span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #000;
}


.modal-actions-btns{


  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
}

.modal-actions-btns button{

  width: 100%;
  border-radius: 15px;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
}

#bluing-btn{
  background: linear-gradient(270deg, #00EEFF -20.9%, #0077FF 153.32%);
  
  
}

.input-box label {

  font-size: 14px;
  margin-bottom: 0.3rem;

}


.modal-actions-btns button:hover {

  
  background-color: #00EEFF;
  border: 0px;
}