
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  min-height: 100vh;
  width: 100%;
  padding-top: 4rem;
}



section {
  position: relative;
  z-index: 3;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
  background-color: #F8FDFF;

}

.container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.section-header {
  margin-bottom: 50px;
  text-align: center;
}

.section-header h2 {
  color: #808080;
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
}

.section-header p {
  color: #808080;
}

.contactrow  {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.contact-info {
  width: 50%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;

}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #D9D9D9;

}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #808080;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #808080;
  font-size: 1em;
}

.contact-form {
  background-color: #fff;
  padding: 40px;
  width: 45%;
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
}

.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #808080;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
  
}


.contact-form .input-box textarea{
  width: 100%;
  height: 7rem;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box textarea::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.contact-form .input-box textarea::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
}

.contact-form .input-box textarea::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #D9D9D9;
}

.contact-form .input-box input
{
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}



.contact-form .input-box input[type="submit"]
{
  width: 100%;
  background: #AFDBF5;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover
{
  background: #0175FF;
  color: #FFF;
}

@media (max-width: 991px) {
  section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .contactrow {
    flex-direction: column;
  }
  
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .contact-form {
    width: 100%;
  }
}


.popup {

  position: fixed;
  border: 3px solid #f1f1f1;
  top: 0px;
  left: 0px;
  visibility: hidden;
  /* transform: translate(-50%, -50%) scale(1); */
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.7);
  z-index: 99998;
}

.popup-content .header h5 {

  font-size: 20px;
  font-weight: 700;
  color: #1F3473;
  padding: 10px;


}

.popup .popup-content {
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 2;
  padding: 10px;
  box-sizing: border-box;
  width: 25rem;
  height: 10rem;

}

.popup .popup-content .form-element {

  padding: 10px;
  padding-bottom: 7px;

}

.popup .popup-content .form-element label {


  font-size: 18px;
  padding-bottom: 3px;
}

.popup .popup-content .form-element input {

  width: 100%;
  height: 40px;
  padding: 5px;

}

.popup .popup-content .header h2 {
  text-align: center;
  color: #51b1e3;
  padding: 5px;
  font-size: 30px;
}

.close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  background: none;
  border: none;
  font-size: 25px;
  font-weight: 700;
  color: #1F3473;
}

.back-btn {
  position: absolute;
  left: 25px;
  top: 7px;
  background: none;
  border: none;
  font-size: 18px;
}

.form-container {
  padding: 10px;
  background-color: white;
}

.open-popup {

  visibility: visible;

}

.check-box-label {

  padding-top: 10px;
  right: 50%;
}




