.Chat-icon-alignment {
    position: fixed; 
    bottom: 20px; 
    right: 20px; 
    z-index: 1000; 
    cursor: pointer; 
  }

  .chatbox-container{
    position: fixed; 
    bottom: 1px; 
    right: 20px; 
    z-index: 1000; 
    cursor: pointer; 
    width: 331px;
  }

  .Chat-icon-alignment button {
    width: 200px;
    height: 48px;
    border: none !important;
    border-radius: 23px;
    padding-left: 1rem;
    padding-right: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 4.62px 0px #00000040;
    z-index: 99;

  }

  

  .Title-top-area{

    color: #FFFFFF;
    font-size: 24px;

  }

  .Chat-icon-alignment img {
      
    margin-left: 1rem;

  }

  .success-modal-container  {

    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .success-modal-container img  {

    margin-bottom: 1rem;

  }


  .Modal {
    background: white;
    padding: 20px;
    border: 0px !important;
    border-radius: 4px;
    width: auto;
    height: auto;
    top: 636px;
    max-width: 500px;
    margin: auto;
    /* Center horizontally */
    position: relative;
    z-index: 2000;
    top: 50%;
    left: 14%;
    transform: translate(-50%, -50%);
    /* Center the modal */
    border-radius: 10px;
    padding: 2rem;
    
  }


  .Overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    /* Ensure overlay covers the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }



  .Modal h2 {

      font-size: 23px;
      text-align: left;
      
  }

  .continue-btn{
    margin-top: 1.5rem;
    width: 100%;
    height: 55px;
    border-radius: 10px;
    background: linear-gradient(270deg, #00EEFF -20.9%, #0077FF 153.32%);
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: center;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    border: 0px;

  }

  .blue-top-area{

    background: linear-gradient(90deg, #0077FF -56.45%, #00EEFF 129.61%);
    border-radius: 20px 20px 0px 0px;
    padding: 1rem;
    
    
  }

  .flexing-top-area{

    display: flex;
    column-gap: 1rem;

  }

  .float-right-icon{

    float: right;
  }

  .float-right-icon img {

    margin-top: -5rem;
    cursor: pointer;
  }

  .chat-history{

      background-color:#F8F9FA  ;
      padding: 1rem;
      min-height: 300px;
      display:flex;
      flex-direction:column-reverse	;
  }

  .message-Client{

    background: linear-gradient(0deg, #01D0FF 0%, #01A0FF 100%);
    border-radius: 40px 40px 1px 40px; /* Top-left, top-right, bottom-right, bottom-left */
    width: 200px;
    float: right;
    height: 100%;    
    margin-top: 1rem;
    width: 100%;
    word-wrap: break-word; /* Ensures words break correctly */
    overflow-wrap: break-word; /* Helps break long words */
  }

  .message-Client p{

    font-size: 14.91px;
    font-weight: 800;
    line-height: 19.42px;
    text-align: left !important;
    color: white;
    padding: 0.7rem;
    margin-bottom: 0rem !important;

  }


  .client-timestamp{

    float: right;
    color: #201F5287;
    font-family: DM Sans;
    font-size: 11px;
    font-weight: 600;
    line-height: 14.32px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    

  }

  

  .message-System {
    background-color: #FFFFFF;
    border-radius: 40px 40px 40px 1px; /* Top-left, top-right, bottom-right, bottom-left */
    max-width: 70%; /* Limits the width to 70% of the parent */
    height: auto !important;
    color: #01A1FF;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-top: 1rem;
    word-wrap: break-word; /* Ensures words break correctly */
    overflow-wrap: break-word; /* Helps break long words */
    padding: 0.7rem; /* Ensures proper spacing */
    display: flex;
    flex-direction: column; /* Stack text and timestamp */
    align-items: flex-start; /* Align to left */
    position: relative;
}

.message-System p {
    font-size: 14.91px;
    font-weight: 700;
    line-height: 19.42px;
    text-align: left; /* Align text correctly */
    margin-bottom: 5px; /* Space between message and timestamp */
    width: 100%;
}

.system-timestamp {
    font-family: DM Sans;
    font-size: 11px;
    font-weight: 600;
    line-height: 14.32px;
    text-align: left;
    color: #201F5287;
    margin-top: 5px; /* Ensure it's below the message */
}
 

.flexing-message-input{


  display: flex;
  justify-content: flex-start;
  width: 100%;

}

.flexing-message-input button {

    border: 0px;
    background-color: #DEE2E6;
    width: 30px;
}

.flexing-message-input .form-control:focus {

  border-color: transparent !important;
  box-shadow: none;
  outline: none;
  
}



@media only screen and (max-width:480px){

  .Modal {
    background: white;
    padding: 20px;
    border: 0px !important;
    border-radius: 4px;
    width: auto;
    height: auto;
    width: 90%;
    margin: auto;
    /* Center horizontally */
    position: relative;
    z-index: 1000;
    top: 50%;
    left:45%;
    transform: translate(-50%, -50%);
    /* Center the modal */
    border-radius: 10px;
    padding: 2rem;
    
  }
  

  .chatbox-container{
    position: fixed; 
    bottom: 0px; 
    right: 20px; 
    z-index: 1000; 
    cursor: pointer; 
    width: 331px;
  }



}



.single-input .form-control {

  box-shadow: 0px 0px 4px 0px #01A3FF;


}

.chat-bubble {
  display: inline-block;
  background-color: #4da8f5; /* Replace with your preferred color */
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 20px 20px 20px 5px; /* Top-left, top-right, bottom-right, bottom-left */
  position: relative;
  max-width: 300px;
  text-align: left;
}

.chat-bubble::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #4da8f5; /* Same as the bubble color */
  border-radius: 50%;
  transform: translate(-200%, -50%);
}

.error-message-email{

  font-size: 14px;
  color: red;
  padding-top: 0.3rem;
  margin: 0px;
}

@media only screen and (max-width: 480px) {

  .chatbox-container{
    position: fixed; 
    bottom: 1px; 
    right: 1px !important; 
    z-index: 1000; 
    cursor: pointer; 
    width: 280px;
  }

  .Chat-icon-alignment {
    position: fixed; 
    bottom: 20px; 
    right: 20px; 
    z-index: 1000; 
    cursor: pointer; 
  }

  .Chat-icon-alignment button {
    width: 160px;
    height: 48px;
    border: none !important;
    border-radius: 23px;
    padding-left: 1rem;
    padding-right: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 4.62px 0px #00000040;
    z-index: 99;

  }

}


@media (min-width: 600px) and (max-width: 1024px) {

  .chatbox-container{
    position: fixed; 
    bottom: 1px; 
    right: 1px !important; 
    z-index: 1000; 
    cursor: pointer; 
    width: 331px;
  }

  .Chat-icon-alignment {
    position: fixed; 
    bottom: 20px; 
    right: 20px; 
    z-index: 1000; 
    cursor: pointer; 
  }

}

