.directory {

    padding-top: 3rem;
    margin-left: 8%;

}

.directory h5,
span {


    font-size: 14px;

}


.directory span {

    color: #0175FF;

}

.card6 {


    margin-left: 7.4%;

}


.card-horizontal3 {

    display: flex;
    flex-direction: row;
    align-items: center;

}


.titles {
    padding-bottom: 2rem;

}

.card-title {

    font-size: 24px;
    font-weight: 700;

}


.card-text {

    font-size: 14px;
    color: #5F5F5F;
    padding-top: 0.5rem;

}

.Email {

    font-size: 18px;
    color: #5E636B;
    padding-bottom: 10px;

}

.Email span {
    color: red;
}

.Password {

    font-size: 18px;
    color: #5E636B;
    padding-bottom: 10px;

}

.Password span {
    color: red;
    font-size: 18px;
}


.form input {

    font-size: 16px;
    color: #939393;
    border-color: #B9B9B9;
    height: 50px;


}

.form .arrow {

    font-size: 16px;
    color: #939393;
    border-color: #B9B9B9;
    height: 50px;
    margin-bottom: 20px;
    cursor: pointer;



}

.label-container {
    display: flex;
    align-items: center;

}


.label-container h6 {

    font-size: 18px;
    margin-top: 10px;
    padding-left: 10px;
    color: #7E7E7E;
}

.label-container span {

    font-size: 18px;
    margin-top: 10px;
    color: #4E4E4E;
    font-weight: 700;
}

.checkbox {

    width: 18px !important;

}



.forgetpassword {


    float: right;
    font-size: 18px;
    padding-bottom: 15px;
    padding-top: 10px;

}

.password-container {

    position: relative;
}

.password-toggle-icon {
    position: absolute;
    top: 70%;
    right: 3%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.loginbtn {


    width: 458px;
    height: 54px;
    border-radius: 8px;


}

.createbtn {
    width: 458px;
    height: 54px;
    background-color: #19CDD9;
    border: 0px;
    border-radius: 8px;



}



.right-img {

    width: 33.375rem;
    height: 33.375rem;
    margin-right: -10%;
    float: right;
}

.right2-img {
    width: 33.375rem;
    height: 655px;
    margin-right: -10%;
    float: right;


}


.right-side-container {

    width: 50%;




}


.card6-body {

    width: 28.625rem;
}

.signup {

    font-size: 16px;
    position: relative;
    display: inline-block;
    padding-top: 10px;

}


.signup::before,
.signup::after {
    content: "———————— ";
    position: absolute;
    top: 30%;
    width: 180px;
    /* Adjust the width of the lines */
    height: 6px;
    /* Adjust the thickness of the lines */
    color: black
        /* Adjust the color of the lines */
}

.signup::before {
    right: 100%;
    margin-right: 15px;
    /* Adjust the spacing between the line and the text */
}

.signup::after {
    left: 100%;
    margin-left: 15px;
    /* Adjust the spacing between the line and the text */
}







 


@media only screen and (min-width: 500px) and (max-width: 1199px) {


    .right2-img {
        width: 20rem;
        height: 600px;
        margin-left: 15% !important;
        float: inline-start;
        border-radius: 10px;

    
    
    }


     .right-img {

        width: 20rem;
        height: 500px;
        margin-left: 15% !important;
        border-radius: 10px;
        float: inline-start;

    } 
    


    .card6-body {

        width: 20.625rem;
    }

    .loginbtn {


        width: 20.625rem;



    }

    .createbtn {
        width: 20.625rem;



    }

    .signup::before,
    .signup::after {
        content: "————— ";
        position: absolute;
        top: 30%;
        width: 110px;
        /* Adjust the width of the lines */
        height: 6px;
        /* Adjust the thickness of the lines */
        color: black
            /* Adjust the color of the lines */
    }

    .signup::before {
        right: 100%;
        margin-right: 15px;
        /* Adjust the spacing between the line and the text */
    }
    
    .signup::after {
        left: 100%;
        margin-left: 15px;
        /* Adjust the spacing between the line and the text */
    }
    

    .card6 {


        margin-left: 6.9%;
        margin-right: 6.9% !important; 
    
    }


}












@media only screen and (max-width:600px) {

    .directory {

        padding-top: 2rem;

    }

    .card-horizontal3 {
        display: flex;
        flex-direction: column-reverse !important;
        clear: both !important;
        margin: auto;
        justify-content: center !important;
        align-items: center;
    }

    .right-img {

        width: 100%;
        height: 146px;
        margin-left: 0px !important;
        border-radius: 10px;
        float: left;



    }

    .right2-img {
        width: 100%;
        height: 146px;
        margin-right: 0px;
        float: left;
        border-radius: 10px;


    }

    .card6 {


        margin-left: 0% !important;

    }

    .card6-body {

        width: 20rem !important;
    }

    .right-side-container {

        width: 90%;
        padding-bottom: 20px;




    }


    .titles {
        padding-bottom: 1rem;

    }

    .loginbtn {


        width: 100%;


    }

    .createbtn {
        width: 100%;



    }

    .signup::before,
    .signup::after {
        content: "—————— ";
        position: absolute;
        top: 30%;
        width: 10px;
        /* Adjust the width of the lines */
        height: 6px;
        /* Adjust the thickness of the lines */
        color: black
            /* Adjust the color of the lines */
    }

    .signup::before {
        right: 50%;
        margin-right: 140px;
        /* Adjust the spacing between the line and the text */
    }

    .signup::after {
        left: 50%;
        margin-left: 50px;
        /* Adjust the spacing between the line and the text */
    }


}

