* {

  font-family: 'Montserrat', sans-serif;

}

.navbar-brand {
  margin-left: 7%;

}

#navbarNav {


  margin-right: 5%;


}

.navbar {
  z-index: 99;
  background-color: #FFFFFF !important;
  box-shadow: 0px 3px 5px #00000008 !important;
}

.navbar-nav a:hover {
  border-bottom: 4px solid #0175FF;
  color: #0175FF !important;
  font-weight: 700;


}




.nav-item {

  padding-left: 10px;

}


.navbar-nav {

  margin-left: auto;



}




.navbar-nav a:hover {
  border-bottom: 4px solid #0175FF;
  color: #0175FF !important;
  font-weight: 700;
  transition: none;


}

@media only screen and (max-width: 820px) {

  .navbar-brand {
    margin-left: 5%;

  }

}



@media only screen and (max-width: 600px) {


  .navbar-brand {
    margin-left: 0%;

  }


}


.request_button {

  width: auto;
  height: 40px;
  background: linear-gradient(270deg, #00EEFF -20.9%, #0077FF 153.32%);
  border: None;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 30px;
  margin-left: 1rem;
  font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 19.36px;
color: #FFFFFF;


}



.request_button:hover {

  color: #0818A8;
  font-weight: 700;
  font-weight: bold;



}