*{

    font-family: 'Montserrat', sans-serif;
  
}

.img-banner-section {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 0px;

}

.layout {

    position: absolute;
    top: 40%;
    left: 13.5%;
    transform: translate(-20%, -50%);
    color: white;
    font-size: 4vw;
    /* Adjust font size using viewport units */
    font-size: 12px;
    color: #231060;
    text-align: left;

}

.layout h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    font-size: 30px;
    width: 31rem;


}

.layout span {
    font-size: 14px;
    color: #808080;
    text-align: left;

}

.second-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 15% !important;
    

  }

  .card1{
    border: 0px;
    width: 15.688rem;
    height: auto;
    position: relative;


  }

  .card1{
    border: 0px;
    width: 15.688rem;
    height: auto;
    position: relative;


  }


  .app-img1{
    width: 105px;
    height: 122px;
  }



  .card-body1{

    font-size: 14px;
    padding: 0px;
    padding-left: 20px;


  }
  .bottom-left1 {
    position: absolute;
    top: 22%;
    left: 10%;
    font-size: 18px;
    font-weight: 700;
  } 

  .bottom-left2 {
    position: absolute;
    top: 21%;
    left: 10%;
    font-size: 18px;
    font-weight: 700;
  } 
  .bottom-left3 {
    position: absolute;
    top: 21%;
    left: 10%;
    font-size: 18px;
    font-weight: 700;
  } 

  .applications-cards-section{
    display: flex;
    /* grid-template-columns: repeat(4, 1fr); */
    flex-wrap: wrap;
    column-gap:3% !important;
    row-gap: 30px !important;
    margin-left: 8.8%;
    margin-right: 8%;

  }
  .header-application-section h2{

    font-size: 20px;
    font-weight: 700;

    margin-left: 9%;
    margin-right: 9%;

  }
  .header-application-section p{

    font-size: 14px;
    font-weight: 500;
    color: #808080;
    margin-left: 9%;
    margin-right: 9%;


  }
  .card2{
    border: 1px solid #D9D9D9;
    width: 22%;
    background-color: #FFFFFF;
  }

  .app-img{

    width: 27px;
    height: 27px; 
    margin-left:10px;
     margin-top:10px;

  }

  .card2-title{
    font-size: 18px ;
    padding-left: 10px;
    padding-top: 10px;
  }


  .card2-body{


    padding-right: 10px;
    padding-bottom: 20px;

  }

  .Partner {

    margin-left: 9%;
    margin-right: 9%;

  }

  .Partner h3 {
    font-size: 20px;
    font-weight: 700;
  }

  .Partner p {
    font-size: 14px;
    font-weight: 500;
    color: #808080;

  }

  .flexing-partners-list{


    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    margin-right: 3%;
    column-gap: 3%;
    row-gap: 10%;
    padding-top: 1rem;
  }

  .flexing-partners-list-element{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    min-width: 194px;
    height: 86px;
    padding: 0.4rem;
    box-shadow: 0px 0px 3.8px 0px #00000040;
    border-radius: 4px;
  }

  .flexing-partners-list-element img{

    width: 100px;


  }




  @media (max-width: 1700px) and (min-width: 1300px) {

    .flexing-partners-list{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr ; 
      column-gap: 3.2%;
      row-gap: 8%;
      clear: both;    
      margin-left: 0% !important;
      margin-right: 2% !important;
    }

  }
  


@media only screen and (max-width: 820px) {

    .img-banner-section {
        padding-top: 0px !important;


    }

    .layout {
        position: absolute;
        top: 15% !important;
        left: 13% !important;
        transform: translate(-20%, -50%);
        color: white;
        font-size: 4vw;
        /* Adjust font size using viewport units */
        color: #231060;
        padding-top: 2.1rem !important;
        clear: both !important;
        margin: auto !important;
    }

    .layout h3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem;
        text-align: left;
        font-size: 18px !important;
        width: 15rem !important;


    }

    .layout span {
        font-size: 12px !important;
        color: #808080;
        text-align: left !important;

    }

    .layout img {

        position: absolute;
        top: 100% !important;
        left: 1%;

    }

    .second-section{
        margin-left: 0% !important;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap !important;

        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        
    
      }

      .card1{
        display: flex;
        flex-direction: column;
        flex: 1 1 50 !important; 
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
      }

      .applications-cards-section{
        clear: both;
        margin: auto;
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        grid-gap:1px !important;
        row-gap: 30px !important;
       
      }
      .header-application-section h2{

        clear: both !important;
    
        margin-left: 3% !important;
        margin-right: 3% !important;
    
      }
    
      .header-application-section p{
    
        margin-left: 3% !important;
        margin-right: 3% !important;
    
      }

      .card2{
        width: 250px !important;
        height:290px !important; 
        clear: both !important;
        margin: auto !important;
      }

      .card2-body{


        padding-bottom: 0px !important;

      }

      .bottom-left1 {
        position: absolute;
        top: 23%;
        left: 36%;
        font-size: 18px;
        font-weight: 700;
      } 
    
      .bottom-left2 {
        position: absolute;
        top: 23%;
        left: 36%;
        font-size: 18px;
        font-weight: 700;
      } 
      .bottom-left3 {
        position: absolute;
        top: 23%;
        left: 36%;
        font-size: 18px;
        font-weight: 700;
      } 

      .flexing-partners-list{
        display: grid;
        grid-template-columns: 1fr 1fr; 
        column-gap: 3%;
        row-gap: 8%;
        clear: both;    
        margin-left: 3% !important;
        margin-right: 3% !important;
      }

      .Partner h3 {
        font-size: 20px;
        font-weight: 700;
        clear: both !important;
    
        margin-left: 3% !important;
        margin-right: 3% !important;
      }
    
      .Partner p {
        font-size: 14px;
        font-weight: 500;
        color: #808080;
        clear: both !important;
        margin-left: 3% !important;
        margin-right: 3% !important;
      }

      .Partner{

        clear: both;
        margin: auto;
        margin-bottom: 1rem;
      }



  .flexing-partners-list-element{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    min-width: 80px;
    height: 86px;
    padding: 0.4rem;
    box-shadow: 0px 0px 3.8px 0px #00000040;
    border-radius: 4px;
  }

  .flexing-partners-list-element img{

    width: 100px;


  }
    


}



@media only screen and (max-width: 600px) {


    .img-banner-section {

        padding-top: 0px !important;
    }

    .layout {

        position: absolute;
        top: 40%;
        left: 18% !important;
        transform: translate(-20%, -50%);
        color: white;
        font-size: 4vw;
        /* Adjust font size using viewport units */
        color: #231060;
        padding-top: 1rem !important;
        clear: both !important;
        margin: auto !important;

    }

    .layout h3 {
        margin-top: 0rem !important;
        margin-bottom: 1rem;
        text-align: left;
        font-size: 12px !important;
        width: 15rem !important;


    }

    .layout span {
        font-size: 12px !important;
        color: #808080;
        text-align: left !important;

    }

    .layout img {

        position: absolute;
        top: 100% !important;
        left: 1%;

    }

    .second-section{
        margin-left: 0% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
    
      }
    
      .bottom-left1 {
       position: absolute;
       
       top: 30%;
       left: 50%;
       /* left: 53.5%; //53.5 */
       transform: translate(-50%, -50%);
    
      } 
    
      .bottom-left2 {
    
        position: absolute;
        top: 28%;
        left: 50%;
        transform: translate(-50%, -50%);
      } 
      .bottom-left3 {
    
        position: absolute;
        top: 28%;
        left: 50%;
        transform: translate(-50%, -50%);
      } 


      .card2{

        width: 170px !important;
        height: 330px !important;
        clear: both !important;
        margin: auto !important;
      }
      .card2-body{


        padding-right: 10px;
        padding-bottom: 0px !important ;
    
      }
    
      .applications-cards-section{
        margin-left: 0% !important;
        margin-right: 0% !important;
    
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:10px !important;
        row-gap: 30px !important;
       
      }
    
      .header-application-section h2{
    
        clear: both !important;
    
        margin-left: 3% !important;
        margin-right: 3% !important;
    
      }
    
      .header-application-section p{
    
        margin-left: 3% !important;
        margin-right: 3% !important;
    
      }
    
}


.omega-img{


  width: 100px !important;
  height: 80px;
}

.banna2{

  width: 70px !important;
  object-fit: contain !important;
}

.diettodoor{


  
}

.padding-app{

  padding-top: 2rem;

}


@media (max-width: 480px) {
  .padding-app{

    padding-top: 10rem !important;
  
  }

}

@media screen and (min-width: 600px) and (max-width: 1024px) {

  .flexing-partners-list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    column-gap: 3%;
    row-gap: 8%;
    clear: both;    
    margin-left: 3% !important;
    margin-right: 3% !important;
  }


}