select{
    appearance: none;
  background: white;
  
  background-image: url("/webapps/gosmart_landingpage/src/assets/Group 162054@2x.png");
  background-repeat: no-repeat;
  background-size: 15px;

  background-position: right 0.8rem center;
  border-radius: 2px;
  padding: 0.25rem 2rem 0.25rem 0.75rem;
  color: grey;
}

